import * as React from "react";
import OurTeamPageComponent from "src/components/pages/about-us/our-team";

const OurTeamPage = ({ location }) => {
  const copy = {
    members: [
      {
        name: "יונתן ספטנר",
        text: `יונתן גדל בסיינט לואיס, עבר משם וחזר לשם ב 1986 לאחר קריירה עסקית מוצלחת בניו יורק סיטי, והצטרף לאביו בעסקי הביטוח המשפחתיים, יונתן מתמקצע בכל ההיבטים של ביטוחי החיים ומשתמש בביטוחי חיים לצורכי נדל”ן, עסקים, ותכנון מס.`,
      },
      {
        name: "אהרן ספטנר",
        text: `אהרון מעורב בכל ההיבטים של אינפורמציה ממוחשבת עבור עסקי הביטוח המשפחתיים של ספטנר, אהרון עיצב ‘’ פורטל לתועלת העובד, שבו משתמשים מעסיקים BENMANAGE" ויישם בהצלחה את ה ’’
בכדי להעסיק עובדים ולהכניס אותם לתוכניות הביטוח הרווחיות שלהם. אהרון מתגורר עם אשתו ושתי ילדיו בביתר עילית, ישראל.`,
      },
      {
        name: "יהודה ספטנר",
        text: `יהודה הצטרף לעסק המשפחתי ב 2010 ומתמקד ביתרונות ביטוח של ביטוחים שנרכשו ע”י עובדים דרך הורדות ישירות מהמשכורת במקום עבודתם. יהודה מתגורר יחד עם אשתו ושלושת ילדיו בסינסנטי, אוהיו.`,
      },
    ],
  };
  return (
    <OurTeamPageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="הצוות שלנו"
    />
  );
};

export default OurTeamPage;
